import React from 'react';
import styled from 'styled-components';
import {MDBContainer, MDBRow, MDBCol, MDBIcon} from 'mdb-react-ui-kit';
import Breadcrumb from '../components/Breadcrumb';

const Styles = styled.div`
`;

export default function Accessibility() {
    const title = 'Accessibility';

    return (
        <Styles>
            <MDBContainer fluid>
                <Breadcrumb active={title}/>
                <MDBRow>
                    <MDBCol size="12">
                        <div className="m-5 info-only">
                            <h2>Accessibility</h2>
                            <p>Thirty Five Park Place is committed to ensuring digital accessibility for people with
                                disabilities. We are continually improving the user experience for everyone, and
                                applying the relevant accessibility standards.</p>
                            <h4>Conformance Status</h4>
                            <p>The Web Content Accessibility Guidelines (WCAG) defines requirements for designers and
                                developers to improve accessibility for people with disabilities. It defines three
                                levels of conformance: Level A, Level AA, and Level AAA. Imaging Insurance is fully
                                conformant with WCAG 2.1 level AA. Fully conformant means that the content fully
                                conforms to the accessibility standard without any exceptions.</p>
                            <h4>Feedback</h4>
                            <p>We welcome your feedback on the accessibility of Thirty Five Park Place Limited. Please
                                let us know if you encounter accessibility barriers:</p>
                            <div className="address">
                                <p>35 Park Place</p>
                                <p>Cardiff</p>
                                <p>CF10 3RL</p>
                                <div className="contact-info">
                                    <p><MDBIcon fas icon="phone"/> PUT PHONE NUMBER HERE</p>
                                    <p><MDBIcon fas icon="envelope"/>
                                        <a href="mailto:info@35parkplace.co.uk"> info@35parkplace.co.uk</a>
                                    </p>
                                </div>
                            </div>
                            <p><br/>We try to respond to feedback within 24 business hours.</p>
                            <p>At Thirty Five Park Place we are aware that many of our customers have varying needs or a
                                disability which may affect how they may view and access the internet.</p>
                            <p>We are committed to making sure that everyone has access to our website and its content.
                                Our aim is to ensure that everyone is able to read the details contained and complete
                                applications online.</p>
                            <h4>Increase the text size</h4>
                            <p>In Internet Explorer, go to the View menu and select Text Size.<br/>
                                In Google Chrome, go to the View menu and select Text Size.<br/>
                                In Mozilla Firefox, go to the View menu and select Text Size.</p>
                            <h4>Disable stylesheets</h4>
                            <p>In Internet Explorer, go to the Tools menu and select Internet Options, click the
                                Accessibility button and disable the StyleSheet.<br/>
                                Customer feedback is important to us. If you discover a part of our website that has
                                been difficult to use, please contact us and let us know.</p>
                        </div>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </Styles>
    )
}
