import React from 'react';
import styled from 'styled-components';
import {MDBContainer} from 'mdb-react-ui-kit';
import Breadcrumb from '../components/Breadcrumb';
import OfficeContainer from "../components/OfficeContainer";
import ContactBar from "../components/ContactBar";
import basementOffices from '../assets/data/basementOffices';
import groundFloorOffices from "../assets/data/groundFloorOffices";
import firstFloorOffices from "../assets/data/firstFloorOffices";
import secondFloorOffices from "../assets/data/secondFloorOffices";
import loftOffices from "../assets/data/loftOffices";

const Styles = styled.div`
`;

export default function Office(props) {
    const allOffices = Array.prototype.concat.apply([], [basementOffices, groundFloorOffices, firstFloorOffices, secondFloorOffices, loftOffices])
    const office = allOffices.find((office) => office.name === props.name);

    return (
        <Styles>
            <MDBContainer fluid className="px-0 overflow-hidden">
                <Breadcrumb items={office.items} active={office.name}/>
                <OfficeContainer images={office.images} name={office.title} price={office.price} size={office.size}
                                 available={office.available} minTerm={office.minTerm} maxTerm={office.maxTerm}
                                 description={office.description} features={office.features} display={office.display}/>
                <ContactBar/>
            </MDBContainer>
        </Styles>
    )
}
