import React, {useState} from 'react';
import styled from 'styled-components';
import {NavDropdown} from 'react-bootstrap';
import {
    MDBNavbar, MDBNavbarNav, MDBNavbarItem, MDBNavbarLink, MDBNavbarBrand, MDBNavbarToggler, MDBContainer, MDBIcon,
    MDBRow, MDBCol, MDBCollapse,
} from 'mdb-react-ui-kit';
import logo from '../assets/images/35-park-place-logo.svg';

const Styles = styled.div`
    header {
        nav {
            background-color: var(--mdb-black);
            border-bottom: 1px solid var(--mdb-green);

            .navbar-brand {                     
                img {
                    margin-top: -14px;
                    height: 60px;
                }
            }
            
            .navbar-toggler {
                i {
                    color: var(--mdb-gray-300);
                }
            }
            
            .navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
                color: var(--mdb-white);
            }

            li > a, li > .dropdown {
                color: var(--mdb-gray-300);
    
                &:hover {
                    color: var(--mdb-white);
                    cursor: pointer;
    
                    .dropdown-menu {
                        display: block;
                    }
                }
            }
    
            .dropdown {
                .dropdown-menu {
                    padding: 0;
                    background-color: var(--mdb-black);
                    
                    @media only screen and (min-width: 992px) {            
                        transform: translate(60px, 44px) !important;
                    }
                    
                    .dropdown-item {
                        height: 2em;
                        padding-bottom: 2.3rem;
    
                        &:hover {
                            outline: none;
                            box-shadow: none;
                            background: none;
                        }
                    }
                }
    
                .offices-navigation {
                    width: 26em;
                    padding-bottom: 1em;
                    
                    @media only screen and (max-width: 992px) {            
                         width: 10em;
                    }
                        
                    .row {                 
                        .parent-offices {
                            border-top-left-radius: 0.2rem;
                            border-bottom-left-radius: 0.2rem;
                            background-color: var(--mdb-green);
    
                            a:hover {
                                color: var(--mdb-black);
                            }
                            
                            @media only screen and (max-width: 992px) {            
                                 border-radius: 0.2rem;
                                 background-color: var(--mdb-green);
                                 
                                a:hover {
                                    color: var(--mdb-black);
                                }
                            }
                        }
                        
                        a {
                            color: var(--mdb-gray-300);
                            font-size: var(--mdb-body-font-size);
    
                            &:hover {
                                color: var(--mdb-white);
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default function Header() {
    const [openNav, setOpenNav] = useState(false);
    const displayBasement = process.env.REACT_APP_DISPLAY_BASEMENT;
    const displayGroundFloor = process.env.REACT_APP_DISPLAY_GROUND_FLOOR;
    const displayFirstFloor = process.env.REACT_APP_DISPLAY_FIRST_FLOOR;
    const displaySecondFloor = process.env.REACT_APP_DISPLAY_SECOND_FLOOR;
    const displayLoft = process.env.REACT_APP_DISPLAY_LOFT;

    const firstAvailableFloor = [displayBasement, displayGroundFloor, displayFirstFloor, displaySecondFloor, displayLoft].findIndex((floor) => floor === 'true');

    const displayB1 = process.env.REACT_APP_DISPLAY_B1;
    const displayB2 = process.env.REACT_APP_DISPLAY_B2;
    const displayG1 = process.env.REACT_APP_DISPLAY_G1;
    const displayG2 = process.env.REACT_APP_DISPLAY_G2;
    const displayG3 = process.env.REACT_APP_DISPLAY_G3;
    const displayG4 = process.env.REACT_APP_DISPLAY_G4;
    const displayG5 = process.env.REACT_APP_DISPLAY_G5;
    const displayF1 = process.env.REACT_APP_DISPLAY_F1;
    const displayF2 = process.env.REACT_APP_DISPLAY_F2;
    const displayF3 = process.env.REACT_APP_DISPLAY_F3;
    const displayS1 = process.env.REACT_APP_DISPLAY_S1;
    const displayS2 = process.env.REACT_APP_DISPLAY_S2;
    const displayS3 = process.env.REACT_APP_DISPLAY_S3;
    const displayS4 = process.env.REACT_APP_DISPLAY_S4;
    const displayL1 = process.env.REACT_APP_DISPLAY_L1;

    const [basementDisplay, setBasementDisplay] = useState(firstAvailableFloor === 0 ? 'display' : 'hide');
    const [basementLink, setBasementLink] = useState(firstAvailableFloor === 0 ? 'hover' : 'no-hover');
    const [groundFloorDisplay, setGroundFloorDisplay] = useState(firstAvailableFloor === 1 ? 'display' : 'hide');
    const [groundFloorLink, setGroundFloorLink] = useState(firstAvailableFloor === 1 ? 'hover' : 'no-hover');
    const [firstFloorDisplay, setFirstFloorDisplay] = useState(firstAvailableFloor === 2 ? 'display' : 'hide');
    const [firstFloorLink, setFirstFloorLink] = useState(firstAvailableFloor === 2 ? 'hover' : 'no-hover');
    const [secondFloorDisplay, setSecondFloorDisplay] = useState(firstAvailableFloor === 3 ? 'display' : 'hide');
    const [secondFloorLink, setSecondFloorLink] = useState(firstAvailableFloor === 3 ? 'hover' : 'no-hover');
    const [loftDisplay, setLoftDisplay] = useState(firstAvailableFloor === 4 ? 'display' : 'hide');
    const [loftLink, setLoftLink] = useState(firstAvailableFloor === 4 ? 'hover' : 'no-hover');

    const showBasement = e => {
        e.preventDefault();
        setBasementDisplay('display');
        setBasementLink('hover');
        setGroundFloorDisplay('hide');
        setGroundFloorLink('no-hover');
        setFirstFloorDisplay('hide');
        setFirstFloorLink('no-hover');
        setSecondFloorDisplay('hide');
        setSecondFloorLink('no-hover');
        setLoftDisplay('hide');
        setLoftLink('no-hover');
    };

    const showGroundFloor = e => {
        e.preventDefault();
        setGroundFloorDisplay('display');
        setGroundFloorLink('hover');
        setBasementDisplay('hide');
        setBasementLink('no-hover');
        setFirstFloorDisplay('hide');
        setFirstFloorLink('no-hover');
        setSecondFloorDisplay('hide');
        setSecondFloorLink('no-hover');
        setLoftDisplay('hide');
        setLoftLink('no-hover');
    };

    const showFirstFloor = e => {
        e.preventDefault();
        setFirstFloorDisplay('display');
        setFirstFloorLink('hover');
        setBasementDisplay('hide');
        setBasementLink('no-hover');
        setGroundFloorDisplay('hide');
        setGroundFloorLink('no-hover');
        setSecondFloorDisplay('hide');
        setSecondFloorLink('no-hover');
        setLoftDisplay('hide');
        setLoftLink('no-hover');
    };

    const showSecondFloor = e => {
        e.preventDefault();
        setSecondFloorDisplay('display');
        setSecondFloorLink('hover');
        setBasementDisplay('hide');
        setBasementLink('no-hover');
        setGroundFloorDisplay('hide');
        setGroundFloorLink('no-hover');
        setFirstFloorDisplay('hide');
        setFirstFloorLink('no-hover');
        setLoftDisplay('hide');
        setLoftLink('no-hover');
    };

    const showLoft = e => {
        e.preventDefault();
        setLoftDisplay('display');
        setLoftLink('hover');
        setBasementDisplay('hide');
        setBasementLink('no-hover');
        setGroundFloorDisplay('hide');
        setGroundFloorLink('no-hover');
        setFirstFloorDisplay('hide');
        setFirstFloorLink('no-hover');
        setSecondFloorDisplay('hide');
        setSecondFloorLink('no-hover');
    };

    return (
        <Styles>
            <header>
                <MDBNavbar expand="lg" fixed="top">
                    <MDBContainer fluid>
                        <MDBNavbarBrand href="/" className="p-0"><img src={logo} alt="logo"/>
                        </MDBNavbarBrand>
                        <MDBNavbarToggler aria-controls="navbarSupportedContent" aria-expanded="false"
                                          aria-label="Toggle navigation" onClick={() => setOpenNav(!openNav)}>
                            <MDBIcon fas icon="bars"/>
                        </MDBNavbarToggler>
                        <MDBCollapse navbar show={openNav}>
                            <MDBNavbarNav className="mb-2 mb-lg-0 w-auto ms-auto">
                                <MDBNavbarItem>
                                    <MDBNavbarLink aria-current="page" href="/">Home</MDBNavbarLink>
                                </MDBNavbarItem>
                                {(displayBasement === 'true' || displayGroundFloor === 'true'
                                        || displayFirstFloor === 'true' || displaySecondFloor === 'true'
                                        || displayLoft === 'true') &&
                                    <MDBNavbarItem>
                                        <NavDropdown title="Offices" id="nav-dropdown" className="dropdown"
                                                     renderMenuOnMount={true}
                                                     onMouseEnter={(e) => document.getElementById('nav-dropdown').click()}>
                                            <MDBContainer className="offices-navigation p-0"
                                                          onMouseLeave={(e) => document.getElementById('nav-dropdown').click()}>
                                                <MDBRow className="d-lg-none">
                                                    <MDBCol size="12" className="parent-offices">
                                                        {displayBasement === 'true' &&
                                                            <NavDropdown.Item href="/offices/basement">
                                                                Basement
                                                            </NavDropdown.Item>
                                                        }
                                                        {displayGroundFloor === 'true' &&
                                                            <NavDropdown.Item href="/offices/ground-floor">
                                                                Ground Floor
                                                            </NavDropdown.Item>
                                                        }
                                                        {displayFirstFloor === 'true' &&
                                                            <NavDropdown.Item href="/offices/first-floor">
                                                                First Floor
                                                            </NavDropdown.Item>
                                                        }
                                                        {displaySecondFloor === 'true' &&
                                                            <NavDropdown.Item href="/offices/second-floor">
                                                                Second Floor
                                                            </NavDropdown.Item>
                                                        }
                                                        {displayLoft === 'true' &&
                                                            <NavDropdown.Item href="/offices/loft">
                                                                Loft
                                                            </NavDropdown.Item>
                                                        }
                                                    </MDBCol>
                                                </MDBRow>
                                                <MDBRow className="d-lg-flex d-none">
                                                    <MDBCol size="7" sm="7" md="7" className="parent-offices">
                                                        {displayBasement === 'true' &&
                                                            <NavDropdown.Item href="/offices/basement"
                                                                              onMouseEnter={e => showBasement(e)}
                                                                              className={basementLink}>
                                                                Basement
                                                            </NavDropdown.Item>
                                                        }
                                                        {displayGroundFloor === 'true' &&
                                                            <NavDropdown.Item href="/offices/ground-floor"
                                                                              onMouseEnter={e => showGroundFloor(e)}
                                                                              className={groundFloorLink}>
                                                                Ground Floor
                                                            </NavDropdown.Item>
                                                        }
                                                        {displayFirstFloor === 'true' &&
                                                            <NavDropdown.Item href="/offices/first-floor"
                                                                              onMouseEnter={e => showFirstFloor(e)}
                                                                              className={firstFloorLink}>
                                                                First Floor
                                                            </NavDropdown.Item>
                                                        }
                                                        {displaySecondFloor === 'true' &&
                                                            <NavDropdown.Item href="/offices/second-floor"
                                                                              onMouseEnter={e => showSecondFloor(e)}
                                                                              className={secondFloorLink}>
                                                                Second Floor
                                                            </NavDropdown.Item>
                                                        }
                                                        {displayLoft === 'true' &&
                                                            <NavDropdown.Item href="/offices/loft"
                                                                              onMouseEnter={e => showLoft(e)}
                                                                              className={loftLink}>
                                                                Loft
                                                            </NavDropdown.Item>
                                                        }
                                                    </MDBCol>
                                                    {displayBasement === 'true' &&
                                                        <MDBCol size="5" sm="5" md="5" className={basementDisplay}>
                                                            <MDBRow>
                                                                <MDBCol size="12" sm="12" md="6"
                                                                        className={basementDisplay}>
                                                                    {displayB1 === 'true' &&
                                                                        <NavDropdown.Item href="/offices/basement/B1">
                                                                            B1
                                                                        </NavDropdown.Item>
                                                                    }
                                                                    {displayB2 === 'true' &&
                                                                        <NavDropdown.Item href="/offices/basement/B2">
                                                                            B2
                                                                        </NavDropdown.Item>
                                                                    }
                                                                </MDBCol>
                                                            </MDBRow>
                                                        </MDBCol>
                                                    }
                                                    {displayGroundFloor === 'true' &&
                                                        <MDBCol size="5" sm="5" md="5"
                                                                className={groundFloorDisplay}>
                                                            <MDBRow>
                                                                <MDBCol size="12" sm="12" md="6"
                                                                        className={groundFloorDisplay}>
                                                                    {displayG1 === 'true' &&
                                                                        <NavDropdown.Item
                                                                            href="/offices/ground-floor/G1">
                                                                            G1
                                                                        </NavDropdown.Item>
                                                                    }
                                                                    {displayG2 === 'true' &&
                                                                        <NavDropdown.Item
                                                                            href="/offices/ground-floor/G2">
                                                                            G2
                                                                        </NavDropdown.Item>
                                                                    }
                                                                    {displayG3 === 'true' &&
                                                                        <NavDropdown.Item
                                                                            href="/offices/ground-floor/G3">
                                                                            G3
                                                                        </NavDropdown.Item>
                                                                    }
                                                                </MDBCol>
                                                                <MDBCol size="12" sm="12" md="6"
                                                                        className={groundFloorDisplay}>
                                                                    {displayG4 === 'true' &&
                                                                        <NavDropdown.Item
                                                                            href="/offices/ground-floor/G4">
                                                                            G4
                                                                        </NavDropdown.Item>
                                                                    }
                                                                    {displayG5 === 'true' &&
                                                                        <NavDropdown.Item
                                                                            href="/offices/ground-floor/G5">
                                                                            G5
                                                                        </NavDropdown.Item>
                                                                    }
                                                                </MDBCol>
                                                            </MDBRow>
                                                        </MDBCol>
                                                    }
                                                    {displayFirstFloor === 'true' &&
                                                        <MDBCol size="5" sm="5" md="5"
                                                                className={firstFloorDisplay}>
                                                            <MDBRow>
                                                                <MDBCol size="12" sm="12" md="6"
                                                                        className={firstFloorDisplay}>
                                                                    {displayF1 === 'true' &&
                                                                        <NavDropdown.Item
                                                                            href="/offices/first-floor/F1">
                                                                            F1
                                                                        </NavDropdown.Item>
                                                                    }
                                                                    {displayF2 === 'true' &&
                                                                        <NavDropdown.Item
                                                                            href="/offices/first-floor/F2">
                                                                            F2
                                                                        </NavDropdown.Item>
                                                                    }
                                                                    {displayF3 === 'true' &&
                                                                        <NavDropdown.Item
                                                                            href="/offices/first-floor/F3">
                                                                            F3
                                                                        </NavDropdown.Item>
                                                                    }
                                                                </MDBCol>
                                                            </MDBRow>
                                                        </MDBCol>
                                                    }
                                                    {displaySecondFloor === 'true' &&
                                                        <MDBCol size="5" sm="5" md="5"
                                                                className={secondFloorDisplay}>
                                                            <MDBRow>
                                                                <MDBCol size="12" sm="12" md="6"
                                                                        className={secondFloorDisplay}>
                                                                    {displayS1 === 'true' &&
                                                                        <NavDropdown.Item
                                                                            href="/offices/second-floor/S1">
                                                                            S1
                                                                        </NavDropdown.Item>
                                                                    }
                                                                    {displayS2 === 'true' &&
                                                                        <NavDropdown.Item
                                                                            href="/offices/second-floor/S2">
                                                                            S2
                                                                        </NavDropdown.Item>
                                                                    }
                                                                    {displayS3 === 'true' &&
                                                                        <NavDropdown.Item
                                                                            href="/offices/second-floor/S3">
                                                                            S3
                                                                        </NavDropdown.Item>
                                                                    }
                                                                </MDBCol>
                                                                <MDBCol size="12" sm="12" md="6"
                                                                        className={secondFloorDisplay}>
                                                                    {displayS4 === 'true' &&
                                                                        <NavDropdown.Item
                                                                            href="/offices/second-floor/S4">
                                                                            S4
                                                                        </NavDropdown.Item>
                                                                    }
                                                                </MDBCol>
                                                            </MDBRow>
                                                        </MDBCol>
                                                    }
                                                    {displayLoft === 'true' &&
                                                        <MDBCol size="5" sm="5" md="5" className={loftDisplay}>
                                                            <MDBRow>
                                                                <MDBCol size="12" sm="12" md="6"
                                                                        className={loftDisplay}>
                                                                    {displayL1 === 'true' &&
                                                                        <NavDropdown.Item href="/offices/loft/L1">
                                                                            L1
                                                                        </NavDropdown.Item>
                                                                    }
                                                                </MDBCol>
                                                            </MDBRow>
                                                        </MDBCol>
                                                    }
                                                </MDBRow>
                                            </MDBContainer>
                                        </NavDropdown>
                                    </MDBNavbarItem>
                                }
                                <MDBNavbarItem>
                                    <MDBNavbarLink href="/contact">Contact</MDBNavbarLink>
                                </MDBNavbarItem>
                            </MDBNavbarNav>
                        </MDBCollapse>
                    </MDBContainer>
                </MDBNavbar>
            </header>
        </Styles>
    );
}
