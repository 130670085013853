import React from 'react';
import styled from 'styled-components';
import {
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBCardImage,
    MDBCardLink,
    MDBCardText,
    MDBCol,
    MDBRow,
} from 'mdb-react-ui-kit';
import ImageGallery from 'react-image-gallery';

const Styles = styled.div`
    .office {
        .image-gallery {
            .image-gallery-image {
                border-radius: 2px;
            }
            
            .image-gallery-icon {
                &:hover {
                    color: var(--mdb-green);
                }
            }
        
            .image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg {
                height: 80px;
                width: 40px;
            }
            
            .image-gallery-fullscreen-button .image-gallery-svg, .image-gallery-play-button .image-gallery-svg {
                height: 20px;
                width: 20px;
            }
            
            .image-gallery-thumbnail.active, .image-gallery-thumbnail:focus {
                outline: none;
                border: 4px solid var(--mdb-green);
                border-radius: 2px;
            }
        }
        
        .summary {             
            h2 {
                color: var(--mdb-gray-300);
            }         
        }
    
        .description {
            color: var(--mdb-gray-300);
    
            h5 {
                color: var(--mdb-green);
            }
        
            ul {
                padding-left: 2rem;
            }
        }
        
        .unavailable {
            p {
                color: var(--mdb-red);
            }
        }
    }
`;

function FloorContainer({images, name, description, features, offices, display}) {
    return (
        <Styles>
            <MDBRow className="office">
                <MDBCol sm="12" md="6">
                    <div className="ms-sm-5 mt-sm-3 me-sm-5 mb-sm-3 ms-md-5 mt-md-3 me-md-0 mb-md-3">
                        <ImageGallery items={images}/>
                    </div>
                </MDBCol>
                <MDBCol sm="12" md="6">
                    <div className="mx-3 mx-sm-5 mt-sm-0 my-md-5 me-md-5">
                        <MDBRow className="my-3 summary">
                            <h2>{name}</h2>
                        </MDBRow>
                        {display === 'true' ? (
                            <MDBRow className="my-3 description">
                                <p>{description}</p>
                                <div className="my-1">
                                    <h5>Key Features</h5>
                                    <ul>
                                        {features?.map((feature, index) => {
                                            return (
                                                <li>{feature}</li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            </MDBRow>
                        ) : (
                            <div className="unavailable">
                                <MDBRow className="my-3">
                                    <p>This floor is currently unavailable.</p>
                                </MDBRow>
                            </div>
                        )}
                    </div>
                </MDBCol>
            </MDBRow>
            {(display === 'true' && offices.filter(o => o.display === 'true').length > 0) &&
                <div>
                    <hr className="hr"/>
                    <MDBRow>
                        <MDBCol size="12">
                            <div className="mx-5 mb-4">
                                <h3>Available {name} Offices</h3>
                                <h5 className="my-4">Select an office below to find out more:</h5>
                            </div>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol size="12">
                            <div className="ms-5 me-5 mb-5">
                                <MDBRow className="row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4">
                                    {offices.filter(o => o.display === 'true').map((service, index) => {
                                        return (
                                            <MDBCol key={index} className="my-2">
                                                <MDBCard className="h-100">
                                                    <MDBCardImage src={service.images[0].original} alt={service.name} position="top"
                                                                  style={{
                                                                      height: 150,
                                                                      objectPosition: service.objectPosition,
                                                                  }}/>
                                                    <MDBCardHeader>{service.name}</MDBCardHeader>
                                                    <MDBCardBody>
                                                        <MDBCardText>
                                                            {service.description}
                                                        </MDBCardText>
                                                        <MDBCardLink href={service.link}>Find out more ></MDBCardLink>
                                                    </MDBCardBody>
                                                </MDBCard>
                                            </MDBCol>
                                        )
                                    })}
                                </MDBRow>
                            </div>
                        </MDBCol>
                    </MDBRow>
                </div>
            }
        </Styles>
    );
}

export default FloorContainer;
