import React from 'react';
import styled from 'styled-components';
import {MDBContainer, MDBRow, MDBCol} from 'mdb-react-ui-kit';
import Breadcrumb from '../components/Breadcrumb';

const Styles = styled.div`
`;

export default function PrivacyPolicy() {
    const title = 'Privacy Policy';

    return (
        <Styles>
            <MDBContainer fluid>
                <Breadcrumb active={title}/>
                <MDBRow>
                    <MDBCol size="12">
                        <div className="m-5 info-only">
                            <h2>Privacy Policy</h2>
                            <p>This Privacy Notice outlines how Thirty Five Park Place Limited “we” or “us” or “our”
                                collects and process data.</p>
                            <p>35PPL is primarily a property management service involved in the administration and
                                renting of commercial offices.</p>
                            <p>References made in this Privacy Notice to “individuals” or “you” or “your” include any
                                living individual whose personal information we receive in connection with the services
                                we provide to our clients.</p>
                            <p>35PPL have an appointed Data Protection Officer to oversee the handling of personal
                                information we collect. If you have any questions about how we collect this data or how
                                we store or use your personal information, you may contact our Data Protection Officer
                                using information in the “Contacting Us” section.</p>
                            <h4>Processing Your Personal Information</h4>
                            <h5>Personal Data</h5>
                            <p>What type of personal data might we collect?</p>
                            <ul>
                                <li>Information such as your name, address and date of birth</li>
                                <li>Contact information</li>
                                <li>Sanctions and credit screening information</li>
                                <li>Financial history and payment details</li>
                                <li>Marketing preferences</li>
                            </ul>
                            <p>We use personal data for the following purposes:</p>
                            <ul>
                                <li>To undertake the performance of a contract of renting to which you are a party</li>
                                <li>To administer your property services</li>
                                <li>To prevent fraud and financial crime</li>
                                <li>Statistical analysis and management information</li>
                                <li>Audits, system integrity checking and risk management</li>
                                <li>To send marketing information about our products and services if we have received
                                    specific consent
                                </li>
                            </ul>
                            <p>We may collect information about you from the following sources:</p>
                            <ul>
                                <li>You or your family members</li>
                                <li>Your representatives</li>
                                <li>Information you have made public (such as via social media)</li>
                                <li>Credit reference or fraud prevention agencies</li>
                                <li>Emergency services, law enforcement agencies, medical and legal practices</li>
                            </ul>
                            <p>If you contact us via email or phone, we may collect additional information during our
                                communication.</p>
                            <h5>Why Might We Collect this Data?</h5>
                            <p>When we hold data, it will only be used in accordance with this privacy notice and this
                                policy should be read in conjunction with the terms of your contract. There is no
                                obligation to provide us with personal or special category information, but if you do
                                not, we may not be able to provide services.</p>
                            <h4>Sharing of Personal Information</h4>
                            <p>We may need to share your personal information with other recipients which could
                                include:</p>
                            <ul>
                                <li>Approved service providers or suppliers or other group companies that provide
                                    support services
                                </li>
                                <li>Purchasers of the whole or part of our business</li>
                            </ul>
                            <h4>Retention of Personal Information</h4>
                            <p>We will normally retain your personal data for up to seven years from when your contract
                                expires. We may also retain telephone call recordings for up to seven years.</p>
                            <h5>What legal basis do we use for processing your personal data?</h5>
                            <p>35PPL will only use and store your personal data if we have a legal basis for doing so.
                                It is your right as the subject of this data to be informed what the legal basis is for
                                each type of processing that we undertake.</p>
                            <ul>
                                <li>We will process your personal data for the purposes of assisting with or
                                    administering services and responding to complaints, on the legal basis that this
                                    processing is necessary for the performance of a contract with you or in the course
                                    of entering into a contract with you. For the purposes above, personal data that is
                                    classed as “special data”, such as information relating to criminal convictions or
                                    medical conditions, will be processed in accordance with the law and on the legal
                                    basis that it is necessary for the performance of a contract necessary for reasons
                                    of substantial public interest. We will follow all appropriate safeguards to ensure
                                    the security of this personal data.
                                </li>
                                <li>We may use your personal data for marketing purposes where we have your consent to
                                    do so. You have the right to request that We do not contact you for marketing
                                    purposes at any time by contacting us – see the Contact Us section below. If you
                                    withdraw consent, then your interests will override ours and We will be unable to
                                    use this legal basis to further process your data.
                                </li>
                                <li>For any processing of personal data for analytical purposes, our legal basis for
                                    processing is that it is necessary for the purposes of a legitimate interest.
                                </li>
                                <li>If your personal data is being used for the purposes of debt recovery, our legal
                                    basis is that processing is necessary for the purposes of a legitimate interest.
                                </li>
                            </ul>
                            <h5>Your Rights</h5>
                            <p>You have the following rights in relation to the data we hold about you, however some of
                                these rights may not apply in certain circumstances – details are noted below. 35PPL
                                have strict internal processes in place that ensure your rights are upheld and that any
                                requests you make in relation to these rights are responded to within 30 days of you
                                making it.</p>
                            <h5>The Right to be Informed</h5>
                            <p>You have the right as a data subject to be informed in a clear and precise manner about
                                the data we hold about you. Within this privacy notice we detail the nature of this data
                                we hold, the reasons we hold it, how this data is used, who we will share this data
                                with, how long we will retain your data and the rights you have in relation to your
                                data. If you require any further information, you can contact us using the details
                                below.</p>
                            <h5>The Right of Access</h5>
                            <p>In order to demonstrate the legitimacy of the personal data we hold on you, its accuracy
                                and the lawfulness of the processing we undertake, you have the right to request a copy
                                of all data we hold about you. You can request this information free of charge using the
                                details below. We will provide a copy of all personal data we hold about you within 30
                                days of you making this request.</p>
                            <h5>The Right to Rectification</h5>
                            <p>You have the right to ensure that all data we hold on you is both accurate and complete.
                                If you are concerned that the data we hold about you is inaccurate or incomplete when
                                considering the purposes for which your data is being used, you can ask us to rectify
                                this. To do so, you should contact us using the details below.</p>
                            <h5>The Right to Erasure – The Right to be Forgotten</h5>
                            <p>You have the right to request that all of the data we hold on you be erased from our
                                systems. We may only be able to comply with this request in specific circumstances. This
                                request would also apply to any third party whom we had shared your data with, and we
                                would notify them accordingly if your request was valid. We will not be able to erase
                                your data in all circumstances. We have a legal obligation to retain this data for the
                                defence of legal claims against you. If you require any further information, or you wish
                                to exercise your right of erasure, you should contact us using the details below.</p>
                            <h5>The Right to Restrict Processing</h5>
                            <p>You have the right to restrict our processing of your data under the following
                                circumstances:</p>
                            <ul>
                                <li>If you contest the accuracy of the information We hold until such time that we can
                                    verify the accuracy of this data or correct any errors
                                </li>
                                <li>You believe that the processing of this data is unlawful</li>
                                <li>We no longer need the data for any purpose other than for the defence of legal
                                    claims
                                </li>
                                <li>You are awaiting a decision following an objection you have raised regarding an
                                    automated decision-making process
                                </li>
                            </ul>
                            <p>If you wish to exercise your right to restrict processing, you should contact us using
                                the details below.</p>
                            <h5>The Right to Data Portability</h5>
                            <p>Where we are processing data under the basis of contractual performance or consent you
                                have the right to request that we provide your data in a machine-readable format that
                                you can then share with other businesses or in any other way you see fit. You have the
                                right to request that we transfer your data to third parties directly for them to use as
                                you see fit. You can utilise your data in this way by contacting us using the details
                                below.</p>
                            <h5>The Right to Object</h5>
                            <p>You have the right to object to your data being processed. The right to object for direct
                                marketing purposes or profiling of your data for the purpose of direct marketing is
                                absolute and we must cease the processing of your data for these purposes. However, for
                                other processing the right to object is not absolute and there may be some compelling
                                reason why we need to continue processing your data. Please contact us using the details
                                below if you want to exercise this right.</p>
                            <h5>The Rights Regarding Automated Decision Making and Profiling</h5>
                            <p>You have the right to request human intervention into any process involving automated
                                decision making where this results in a legal implication to you. Currently, we do not
                                use automated decision making for any functions, but if you have concerns regarding
                                this, please contact us using the details below.</p>
                            <h5>Changes to the Privacy Policy</h5>
                            <p>We reserve the right to update this privacy policy. Any changes will be communicated
                                through our website.</p>
                            <h5>Contact Information</h5>
                            <p>If you have any questions or concerns regarding this privacy policy, please contact us
                                at:</p>
                            <div className="address">
                                <p>Thirty-Five Park Place Management</p>
                                <p>Sureland House</p>
                                <p>Journeymans Way</p>
                                <p>Southend-On-Sea</p>
                                <p>Essex</p>
                                <p>SS2 5TF</p>
                            </div>
                            <br/>
                            <p>If you have any questions about how we use personal information, you can contact our Data
                                Protection Officers as follows:
                                <a href="mailto:info@35parkplace.co.uk"> info@35parkplace.co.uk</a>
                            </p>
                        </div>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </Styles>
    )
}
