import React from 'react'
import styled from 'styled-components';
import {MDBContainer, MDBRow, MDBCol, MDBIcon} from 'mdb-react-ui-kit';
import {GoogleMap, useJsApiLoader, Marker} from '@react-google-maps/api';
import TitleContainer from '../components/TitleContainer';
import Breadcrumb from '../components/Breadcrumb';

const Styles = styled.div`
    margin-bottom: 0.5rem;

    .address {
        color: var(--mdb-gray-300);
    
        p {
            margin-bottom: 0;
        }

        .contact-info {
            padding-top: 20px;
            
            p, a {
                color: var(--mdb-green);
            }
        }
    }
`;

const containerStyle = {
    //width: '530px',
    height: '400px',
    opacity: 0.7,
    borderRadius: '3px',
};

const center = {
    lat: 51.4864601827936,
    lng: -3.176644608396543,
};

export default function Contact() {
    const title = 'Contact';
    const {isLoaded} = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyDoNt7PPSprFnjXuhM_t9xSl3fKu2n7018',
    })

    const [map, setMap] = React.useState(null)

    const onLoad = React.useCallback(function callback(map) {
        // const bounds = new window.google.maps.LatLngBounds(center);
        // map.fitBounds(bounds);
        setMap(map);
    }, []);

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null);
    }, [])

    return isLoaded ? (
        <Styles>
            <MDBContainer fluid className="px-0 overflow-hidden">
                <TitleContainer title={title}/>
                <Breadcrumb active={title}/>
                <MDBRow className="mb-3">
                    <MDBCol sm="12" md="6">
                        <div className="ms-sm-5 mt-sm-3 me-sm-5 mb-sm-3 ms-md-5 mt-md-3 me-md-0 mb-md-3">
                            <GoogleMap
                                mapContainerStyle={containerStyle}
                                center={center}
                                zoom={10}
                                onLoad={onLoad}
                                onUnmount={onUnmount}>
                                <Marker position={center}/>
                                { /* Child components, such as markers, info windows, etc. */}
                                <></>
                            </GoogleMap>
                        </div>
                    </MDBCol>
                    <MDBCol sm="12" md="6">
                        <div className="mx-3 mx-sm-5 mt-sm-0 my-md-5 me-md-5">
                            <MDBRow className="mb-3">
                                <MDBCol size="12">
                                    <div className="address">
                                        <h2>Head Office</h2>
                                        <p>35 Park Place</p>
                                        <p>Cardiff</p>
                                        <p>CF10 3RL</p>
                                        <div className="contact-info">
                                            <p><MDBIcon fas icon="phone"/> 02922 710400</p>
                                            <p><MDBIcon fas icon="envelope"/>
                                                <a href="mailto:info@35parkplace.co.uk"> info@35parkplace.co.uk</a>
                                            </p>
                                        </div>
                                    </div>
                                </MDBCol>
                            </MDBRow>
                        </div>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </Styles>
    ) : <></>
}
