import React from 'react';
import styled from 'styled-components';
import {MDBContainer} from 'mdb-react-ui-kit';
import Breadcrumb from '../components/Breadcrumb';
import FloorContainer from "../components/FloorContainer";
import ContactBar from "../components/ContactBar";
import floors from '../assets/data/floors';
import basementOffices from '../assets/data/basementOffices';
import groundFloorOffices from "../assets/data/groundFloorOffices";
import firstFloorOffices from "../assets/data/firstFloorOffices";
import secondFloorOffices from "../assets/data/secondFloorOffices";
import loftOffices from "../assets/data/loftOffices";

const Styles = styled.div`
`;

export default function Office(props) {
    const floor = floors.find((floor) => floor.name === props.name);

    let offices = [];

    switch (props.name) {
        case 'basement':
            offices = basementOffices;
            break;
        case 'groundFloor':
            offices = groundFloorOffices;
            break;
        case 'firstFloor':
            offices = firstFloorOffices;
            break;
        case 'secondFloor':
            offices = secondFloorOffices;
            break;
        case 'loft':
            offices = loftOffices;
            break;
        default:
            offices = [];
    }

    return (
        <Styles>
            <MDBContainer fluid className="px-0 overflow-hidden">
                <Breadcrumb items={floor.items} active={floor.title}/>
                <FloorContainer images={floor.images} name={floor.title} description={floor.description}
                                features={floor.features} offices={offices} display={floor.display}/>
                <ContactBar/>
            </MDBContainer>
        </Styles>
    )
}
