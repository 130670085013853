import React from 'react';
import styled from 'styled-components';
import {MDBBreadcrumb, MDBBreadcrumbItem} from 'mdb-react-ui-kit';

const Styles = styled.div`
    .breadcrumb {
        background: none;

        li {
            &.breadcrumb-item {
                font-size: 0.8rem;
    
                a {
                    color: var(--mdb-gray-300);
    
                    &:hover {
                        color: var(--mdb-white);
                        text-decoration: none;
                    }
                }
    
                &.active {
                    color: var(--mdb-green);
                }
            }
        }
    }
`;

function Breadcrumb({items, active}) {
    return (
        <Styles>
            <MDBBreadcrumb className="pt-2 ps-5 mb-0">
                <MDBBreadcrumbItem>
                    <a href="/">Home</a>
                </MDBBreadcrumbItem>
                {items?.map((item, index) => {
                    return (
                        <MDBBreadcrumbItem key={index}>
                            <a href={item.link}>{item.name}</a>
                        </MDBBreadcrumbItem>
                    )
                })}
                <MDBBreadcrumbItem active>{active}</MDBBreadcrumbItem>
            </MDBBreadcrumb>
        </Styles>
    );
}

export default Breadcrumb;
