import floorplan_basement from '../images/basement-floorplan-v.png';
import floorplan_ground_floor from '../images/ground-floor-floorplan-v.png';
import floorplan_first_floor from '../images/first-floor-floorplan-v.png';
import floorplan_second_floor from '../images/second-floor-floorplan-v.png';
import floorplan_loft from '../images/loft-floorplan-v.png';

import second_floor_1 from '../images/second-floor-1.jpg';
import second_floor_2 from '../images/second-floor-2.jpg';
import loft_1 from '../images/L1-2.jpg';

const floors = [
    {
        name: 'basement',
        items: [{name: 'Offices', link: '/offices'}],
        title: 'Basement',
        description: 'A floor suited for those who like a quick cuppa!',
        features: ['Kitchen facilities located on this floor',
            'Secluded floor for more discrete clients',
            'Access to rear entrance'],
        images: [

            {
                original: floorplan_basement,
                thumbnail: floorplan_basement,
            },
        ],
        display: process.env.REACT_APP_DISPLAY_BASEMENT,
    },
    {
        name: 'groundFloor',
        items: [{name: 'Offices', link: '/offices'}],
        title: 'Ground Floor',
        description: 'A welcome area for you and your clients.',
        features: ['Foyer and main entrance located on this floor',
            'Seated waiting area',
            'Easy access to toilet facilities'],
        images: [
            {
                original: floorplan_ground_floor,
                thumbnail: floorplan_ground_floor,
            },
        ],
        display: process.env.REACT_APP_DISPLAY_GROUND_FLOOR,
    },
    {
        name: 'firstFloor',
        items: [{name: 'Offices', link: '/offices'}],
        title: 'First Floor',
        description: 'A floor with flexible interconnecting offices.',
        features: ['Suite of offices with well lit and airy landing',
            'Can be rented as an interconnected floor, subject to availability',
            'Easy access to toilet facilities'],
        images: [
            {
                original: floorplan_first_floor,
                thumbnail: floorplan_first_floor,
            },
        ],
        display: process.env.REACT_APP_DISPLAY_FIRST_FLOOR,
    },
    {
        name: 'secondFloor',
        items: [{name: 'Offices', link: '/offices'}],
        title: 'Second Floor',
        description: 'A floor with flexible interconnecting offices.',
        features: ['Suite of offices with well lit and airy landing',
            'Majority of floor can be rented as an interconnected ensemble, subject to availability',
            'Picturesque views of the surrounding area'],
        images: [
            {
                original: second_floor_1,
                thumbnail: second_floor_1,
            },
            {
                original: second_floor_2,
                thumbnail: second_floor_2,
            },
            {
                original: floorplan_second_floor,
                thumbnail: floorplan_second_floor,
            },
        ],
        display: process.env.REACT_APP_DISPLAY_SECOND_FLOOR,
    },
    {
        name: 'loft',
        items: [{name: 'Offices', link: '/offices'}],
        title: 'Loft',
        description: 'A space for those who like to rise above it all!',
        features: ['Single open plan office encompassing entire floor',
            'Private floor with no adjoining neighbours',
            'High location detached from street noise'],
        images: [
            {
                original: loft_1,
                thumbnail: loft_1,
            },
            {
                original: floorplan_loft,
                thumbnail: floorplan_loft,
            },
        ],
        display: process.env.REACT_APP_DISPLAY_LOFT,
    },
];

export default floors;
