import React from 'react';
import styled from 'styled-components';
import {MDBCol, MDBRow} from 'mdb-react-ui-kit';
import ImageGallery from 'react-image-gallery';

const Styles = styled.div`
    .office {
        .image-gallery {
            .image-gallery-image {
                border-radius: 2px;
            }
            
            .image-gallery-icon {
                &:hover {
                    color: var(--mdb-green);
                }
            }
        
            .image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg {
                height: 80px;
                width: 40px;
            }
            
            .image-gallery-fullscreen-button .image-gallery-svg, .image-gallery-play-button .image-gallery-svg {
                height: 20px;
                width: 20px;
            }
            
            .image-gallery-thumbnail.active, .image-gallery-thumbnail:focus {
                outline: none;
                border: 4px solid var(--mdb-green);
                border-radius: 2px;
            }
        }
        
        .summary {
            div {
                align-items: baseline;
            }
        
            h2, h3 {
                color: var(--mdb-gray-300);
            }
        
            h5 {
                color: var(--mdb-gray-500);
            }
        
            .vr {
                color: var(--mdb-green);
                opacity: 1;
            }
        }
    
        .breakdown {
            border-top: 1px solid var(--mdb-green);
            border-bottom: 1px solid var(--mdb-green);
        
            div {
                p {
                    display: inline-block;
                    
                    &:first-child {
                        margin-top: 1rem;
                        margin-bottom: 0rem;
                    }                      
                }
            }
        }
    
        .description {
            color: var(--mdb-gray-300);
    
            h5 {
                color: var(--mdb-green);
            }
        
            ul {
                padding-left: 2rem;
            }
        }
        
        .unavailable {
            p {
                color: var(--mdb-red);
            }
        }
    }
`;

function OfficeContainer({images, name, price, size, available, minTerm, maxTerm, description, features, display}) {
    return (
        <Styles>
            <MDBRow className="office">
                <MDBCol sm="12" md="6">
                    <div className="ms-sm-5 mt-sm-3 me-sm-5 mb-sm-3 ms-md-5 mt-md-3 me-md-0 mb-md-3">
                        <ImageGallery items={images}/>
                    </div>
                </MDBCol>
                <MDBCol sm="12" md="6">
                    <div className="mx-3 mx-sm-5 mt-sm-0 my-md-5 me-md-5">
                        <MDBRow className="my-3 summary">
                            <h2>{name}</h2>
                            <div className="d-flex">
                                <h3>{price}</h3>
                                <div className="vr mx-3"></div>
                                <h5>{size}</h5>
                            </div>
                        </MDBRow>
                        {display === 'true' ? (
                            <div>
                                <MDBRow className="my-3 breakdown">
                                    <MDBCol md="6" lg="4">
                                        <p><b>Available Date:</b>&nbsp;</p>
                                        <p>{available}</p>
                                    </MDBCol>
                                    <MDBCol md="6" lg="4">
                                        <p><b>Min. Term:</b>&nbsp;</p>
                                        <p>{minTerm}</p>
                                    </MDBCol>
                                    <MDBCol md="6" lg="4">
                                        <p><b>Max. Term:</b>&nbsp;</p>
                                        <p>{maxTerm}</p>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow className="my-3 description">
                                    <p>{description}</p>
                                    <div className="my-1">
                                        <h5>Key Features</h5>
                                        <ul>
                                            {features?.map((feature, index) => {
                                                return (
                                                    <li>{feature}</li>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                </MDBRow>
                                <p>All sizes are approximate. Prices are not subject to VAT and do not include business
                                    rates.</p>
                            </div>
                        ) : (
                            <div className="unavailable">
                                <MDBRow className="my-3">
                                    <p>This office is currently unavailable.</p>
                                </MDBRow>
                            </div>
                        )}
                    </div>
                </MDBCol>
            </MDBRow>
        </Styles>
    );
}

export default OfficeContainer;
