import floorplan_S1 from '../images/S1-floorplan-v.png';
import floorplan_S2 from '../images/S2-floorplan-v.png';
import floorplan_S3 from '../images/S3-floorplan-v.png';
import floorplan_S4 from '../images/S4-floorplan-v.png';

import S1_1 from '../images/S1-1.jpg';
import S2_1 from '../images/S2-1.jpg';
import S2_2 from '../images/S2-2.jpg';
import S3_1 from '../images/S3-1.jpg';
import S4_1 from '../images/S4-1.jpg';

const secondFloorOffices = [
    {
        name: 'S1',
        items: [{name: 'Offices', link: '/offices'}, {name: 'Second Floor', link: '/offices/second-floor'}],
        title: 'Second Floor - Office 1',
        price: '£450 pcm',
        size: '4m x 2.5m',
        available: '01/09/2024',
        minTerm: '12 months',
        maxTerm: '36 months',
        description: 'A small office with sun all day round!',
        features: ['South facing office with an abundance of natural light',
            'Ideal office for small businesses',
            'Can be rented as a single office or in conjunction with S2 via the adjoining door, subject to availability'],
        images: [
            {
                original: S1_1,
                thumbnail: S1_1,
            },
            {
                original: floorplan_S1,
                thumbnail: floorplan_S1,
            },
        ],
        link: '/offices/second-floor/S1',
        objectPosition: '0 50%',
        display: process.env.REACT_APP_DISPLAY_S1,
    },
    {
        name: 'S2',
        items: [{name: 'Offices', link: '/offices'}, {name: 'Second Floor', link: '/offices/second-floor'}],
        title: 'Second Floor - Office 2',
        price: '£595 pcm',
        size: '5m x 4m',
        available: '01/09/2024',
        minTerm: '12 months',
        maxTerm: '36 months',
        description: 'A medium sized office with plenty of integrated storage.',
        features: ['South West facing office with an abundance of natural light',
            'Spacious integrated storage unit',
            'Can be rented as a single office or in conjunction with S1/S3 via the adjoining doors, subject to availability'],
        images: [
            {
                original: S2_1,
                thumbnail: S2_1,
            },
            {
                original: S2_2,
                thumbnail: S2_2,
            },
            {
                original: floorplan_S2,
                thumbnail: floorplan_S2,
            },
        ],
        link: '/offices/second-floor/S2',
        objectPosition: '0 50%',
        display: process.env.REACT_APP_DISPLAY_S2,
    },
    {
        name: 'S3',
        items: [{name: 'Offices', link: '/offices'}, {name: 'Second Floor', link: '/offices/second-floor'}],
        title: 'Second Floor - Office 3',
        price: '£580 pcm',
        size: '4m x 4m',
        available: '01/09/2024',
        minTerm: '12 months',
        maxTerm: '36 months',
        description: 'A medium sized office great for growing business.',
        features: ['Peaceful office detached from street noise',
            'Ideal office for collaboration',
            'Can be rented as a single office or in conjunction with S2 via the adjoining door, subject to availability'],
        images: [
            {
                original: S3_1,
                thumbnail: S3_1,
            },
            {
                original: floorplan_S3,
                thumbnail: floorplan_S3,
            },
        ],
        link: '/offices/second-floor/S3',
        objectPosition: '0 50%',
        display: process.env.REACT_APP_DISPLAY_S3,
    },
    {
        name: 'S4',
        items: [{name: 'Offices', link: '/offices'}, {name: 'Second Floor', link: '/offices/second-floor'}],
        title: 'Second Floor - Office 4',
        price: '£425 pcm',
        size: '3m x 2m',
        available: '01/09/2024',
        minTerm: '12 months',
        maxTerm: '36 months',
        description: 'A small office away from the hustle and bustle!',
        features: ['Peaceful office detached from street noise',
            'Ideal office for independent consultation or as a private meeting space'],
        images: [
            {
                original: S4_1,
                thumbnail: S4_1,
            },
            {
                original: floorplan_S4,
                thumbnail: floorplan_S4,
            },
        ],
        link: '/offices/second-floor/S4',
        objectPosition: '0 50%',
        display: process.env.REACT_APP_DISPLAY_S4,
    },
];

export default secondFloorOffices;
