import floorplan_F1 from '../images/F1-floorplan-v.png';
import floorplan_F2 from '../images/F2-floorplan-v.png';
import floorplan_F3 from '../images/F3-floorplan-v.png';

const firstFloorOffices = [
    {
        name: 'F1',
        items: [{name: 'Offices', link: '/offices'}, {name: 'First Floor', link: '/offices/first-floor'}],
        title: 'First Floor - Office 1',
        price: '£ TBD pcm',
        size: '4m x 2.5m',
        available: 'TBD',
        minTerm: '12 months',
        maxTerm: '36 months',
        description: 'A little office with a lot of light!',
        features: ['South West facing office with an abundance of natural light',
            'Ideal office for small businesses',
            'Can be rented as a single office or in conjunction with F2 via the adjoining door, subject to availability'],
        images: [
            {
                original: floorplan_F1,
                thumbnail: floorplan_F1,
            },
        ],
        link: '/offices/first-floor/F1',
        objectPosition: '0 50%',
        display: process.env.REACT_APP_DISPLAY_F1,
    },
    {
        name: 'F2',
        items: [{name: 'Offices', link: '/offices'}, {name: 'First Floor', link: '/offices/first-floor'}],
        title: 'First Floor - Office 2',
        price: '£ TBD pcm',
        size: '5m x 4m',
        available: 'TBD',
        minTerm: '12 months',
        maxTerm: '36 months',
        description: 'A medium sized office with hard flooring throughout.',
        features: ['South West facing office with an abundance of natural light',
            'Professionally fitted laminate flooring',
            'Can be rented as a single office or in conjunction with F1/F3 via the adjoining doors, subject to availability'],
        images: [
            {
                original: floorplan_F2,
                thumbnail: floorplan_F2,
            },
        ],
        link: '/offices/first-floor/F2',
        objectPosition: '0 50%',
        display: process.env.REACT_APP_DISPLAY_F2,
    },
    {
        name: 'F3',
        items: [{name: 'Offices', link: '/offices'}, {name: 'First Floor', link: '/offices/first-floor'}],
        title: 'First Floor - Office 3',
        price: '£ TBD pcm',
        size: '4m x 4m',
        available: 'TBD',
        minTerm: '12 months',
        maxTerm: '36 months',
        description: 'A medium sized office with hard flooring throughout.',
        features: ['Optional installed sink facility',
            'Professionally fitted laminate flooring',
            'Can be rented as a single office or in conjunction with F2 via the adjoining door, subject to availability'],
        images: [
            {
                original: floorplan_F3,
                thumbnail: floorplan_F3,
            },
        ],
        link: '/offices/first-floor/F3',
        objectPosition: '0 50%',
        display: process.env.REACT_APP_DISPLAY_F3,
    },
];

export default firstFloorOffices;
