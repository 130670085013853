import React, {Component} from 'react';
import {BrowserRouter, Routes, Route, Navigate} from 'react-router-dom';
import styled from 'styled-components';
import Home from './views/Home';
import Contact from './views/Contact';
import Accessibility from './views/Accessibility';
import PrivacyPolicy from './views/PrivacyPolicy';
import Terms from './views/Terms';
import Offices from './views/Offices';
import Floor from './views/Floor';
import Office from './views/Office';
import {Layout} from './components/Layout';
import Header from './components/Header';
import {Footer} from './components/Footer';
import './assets/scss/colours.scss';
import './assets/scss/fonts.scss';
import './assets/scss/main.scss';

const Styles = styled.div`
    .site {
        display: flex;
        min-height: 100vh;
        flex-direction: column;
    }

    .site-content {
        flex: 1;
        margin-top: 64px;
    }
`;

class App extends Component {
    render() {
        return (
            <Styles>
                <div className="site">
                    <React.Fragment>
                        <Header/>
                        <div className="site-content">
                            <BrowserRouter>
                                <Layout>
                                    <Routes>
                                        <Route path="/" element={<Home/>}/>
                                        <Route path="/contact" element={<Contact/>}/>
                                        <Route path="/accessibility" element={<Accessibility/>}/>
                                        <Route path="/privacypolicy" element={<PrivacyPolicy/>}/>
                                        <Route path="/terms" element={<Terms/>}/>
                                        <Route path="/offices" element={<Offices/>}/>
                                        <Route path="/offices/basement" element={<Floor name={"basement"}/>}/>
                                        <Route path="/offices/basement/B1" element={<Office name={"B1"}/>}/>
                                        <Route path="/offices/basement/B2" element={<Office name={"B2"}/>}/>
                                        <Route path="/offices/ground-floor" element={<Floor name={"groundFloor"}/>}/>
                                        <Route path="/offices/ground-floor/G1" element={<Office name={"G1"}/>}/>
                                        <Route path="/offices/ground-floor/G2" element={<Office name={"G2"}/>}/>
                                        <Route path="/offices/ground-floor/G3" element={<Office name={"G3"}/>}/>
                                        <Route path="/offices/ground-floor/G4" element={<Office name={"G4"}/>}/>
                                        <Route path="/offices/ground-floor/G5" element={<Office name={"G5"}/>}/>
                                        <Route path="/offices/first-floor" element={<Floor name={"firstFloor"}/>}/>
                                        <Route path="/offices/first-floor/F1" element={<Office name={"F1"}/>}/>
                                        <Route path="/offices/first-floor/F2" element={<Office name={"F2"}/>}/>
                                        <Route path="/offices/first-floor/F3" element={<Office name={"F3"}/>}/>
                                        <Route path="/offices/second-floor" element={<Floor name={"secondFloor"}/>}/>
                                        <Route path="/offices/second-floor/S1" element={<Office name={"S1"}/>}/>
                                        <Route path="/offices/second-floor/S2" element={<Office name={"S2"}/>}/>
                                        <Route path="/offices/second-floor/S3" element={<Office name={"S3"}/>}/>
                                        <Route path="/offices/second-floor/S4" element={<Office name={"S4"}/>}/>
                                        <Route path="/offices/loft" element={<Floor name={"loft"}/>}/>
                                        <Route path="/offices/loft/L1" element={<Office name={"L1"}/>}/>
                                        <Route path="*" element={<Navigate replace to="/"/>}/>
                                    </Routes>
                                </Layout>
                            </BrowserRouter>
                        </div>
                        <Footer/>
                    </React.Fragment>
                </div>
            </Styles>
        );
    }
}

export default App;
