import floorplan_B1 from '../images/B1-floorplan-v.png';
import floorplan_B2 from '../images/B2-floorplan-v.png';

const basementOffices = [
    {
        name: 'B1',
        items: [{name: 'Offices', link: '/offices'}, {name: 'Basement', link: '/offices/basement'}],
        title: 'Basement - Office 1',
        price: '£ TBD pcm',
        size: 'TBD m x TBD m',
        available: 'TBD',
        minTerm: '12 months',
        maxTerm: '36 months',
        description: 'A large office with plenty of integrated storage.',
        features: ['Large bay window',
            'Easy access to kitchen facilities',
            'Spacious integrated storage unit'],
        images: [
            {
                original: floorplan_B1,
                thumbnail: floorplan_B1,
            },
        ],
        link: '/offices/basement/B1',
        objectPosition: '0 50%',
        display: process.env.REACT_APP_DISPLAY_B1,
    },
    {
        name: 'B2',
        items: [{name: 'Offices', link: '/offices'}, {name: 'Basement', link: '/offices/basement'}],
        title: 'Basement - Office 2',
        price: '£ TBD pcm',
        size: 'TBD m x TBD m',
        available: 'TBD',
        minTerm: '12 months',
        maxTerm: '36 months',
        description: 'A medium sized office tucked away for those seeking privacy.',
        features: ['Only one neighbouring office',
            'Easy access to kitchen facilities',
            'Ideal office for private consultations'],
        images: [
            {
                original: floorplan_B2,
                thumbnail: floorplan_B2,
            },
        ],
        link: '/offices/basement/B2',
        objectPosition: '0 50%',
        display: process.env.REACT_APP_DISPLAY_B2,
    },
];

export default basementOffices;
