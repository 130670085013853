import floorplan_G1 from '../images/G1-floorplan-v.png';
import floorplan_G2 from '../images/G2-floorplan-v.png';
import floorplan_G3 from '../images/G3-floorplan-v.png';
import floorplan_G4 from '../images/G4-floorplan-v.png';
import floorplan_G5 from '../images/G5-floorplan-v.png';

const groundFloorOffices = [
    {
        name: 'G1',
        items: [{name: 'Offices', link: '/offices'}, {name: 'Ground Floor', link: '/offices/ground-floor'}],
        title: 'Ground Floor - Office 1',
        price: '£ TBD pcm',
        size: 'TBD m x TBD m',
        available: 'TBD',
        minTerm: '12 months',
        maxTerm: '36 months',
        description: 'A small office steps away from the front door!',
        features: ['Easy access to foyer',
            'Closest to front entrance',
            'Can be rented as a single office or in conjunction with G2 via the adjoining door, subject to availability'],
        images: [
            {
                original: floorplan_G1,
                thumbnail: floorplan_G1,
            },
        ],
        link: '/offices/ground-floor/G1',
        objectPosition: '0 50%',
        display: process.env.REACT_APP_DISPLAY_G1,
    },
    {
        name: 'G2',
        items: [{name: 'Offices', link: '/offices'}, {name: 'Ground Floor', link: '/offices/ground-floor'}],
        title: 'Ground Floor - Office 2',
        price: '£ TBD pcm',
        size: 'TBD m x TBD m',
        available: 'TBD',
        minTerm: '12 months',
        maxTerm: '36 months',
        description: 'A medium sized office perfect for welcoming guests.',
        features: ['Easy access to foyer',
            'Floor installed power sockets',
            'Large bay window',
            'Can be rented as a single office or in conjunction with G1/G3 via the adjoining doors, subject to availability'],
        images: [
            {
                original: floorplan_G2,
                thumbnail: floorplan_G2,
            },
        ],
        link: '/offices/ground-floor/G2',
        objectPosition: '0 50%',
        display: process.env.REACT_APP_DISPLAY_G2,
    },
    {
        name: 'G3',
        items: [{name: 'Offices', link: '/offices'}, {name: 'Ground Floor', link: '/offices/ground-floor'}],
        title: 'Ground Floor - Office 3',
        price: '£ TBD pcm',
        size: 'TBD m x TBD m',
        available: 'TBD',
        minTerm: '12 months',
        maxTerm: '36 months',
        description: 'A medium sized office perfect for welcoming guests.',
        features: ['Easy access to foyer',
            'Floor installed power sockets',
            'Can be rented as a single office or in conjunction with G2 via the adjoining door, subject to availability'],
        images: [
            {
                original: floorplan_G3,
                thumbnail: floorplan_G3,
            },
        ],
        link: '/offices/ground-floor/G3',
        objectPosition: '0 50%',
        display: process.env.REACT_APP_DISPLAY_G3,
    },
    {
        name: 'G4',
        items: [{name: 'Offices', link: '/offices'}, {name: 'Ground Floor', link: '/offices/ground-floor'}],
        title: 'Ground Floor - Office 4',
        price: '£ TBD pcm',
        size: 'TBD m x TBD m',
        available: 'TBD',
        minTerm: '12 months',
        maxTerm: '36 months',
        description: 'A small office perfect for welcoming guests.',
        features: ['Easy access to foyer',
            'Easy access to kitchen facilities',
            'Ideal office for independent consultation or as a private meeting space'],
        images: [
            {
                original: floorplan_G4,
                thumbnail: floorplan_G4,
            },
        ],
        link: '/offices/ground-floor/G4',
        objectPosition: '0 50%',
        display: process.env.REACT_APP_DISPLAY_G4,
    },
    {
        name: 'G5',
        items: [{name: 'Offices', link: '/offices'}, {name: 'Ground Floor', link: '/offices/ground-floor'}],
        title: 'Ground Floor - Office 5',
        price: '£ TBD pcm',
        size: 'TBD m x TBD m',
        available: 'TBD',
        minTerm: '12 months',
        maxTerm: '36 months',
        description: 'A small office with two windows for the price of one!',
        features: ['Easy access to foyer',
            'Frosted glass sliding entry door',
            'South East facing office with an abundance of natural light'],
        images: [
            {
                original: floorplan_G5,
                thumbnail: floorplan_G5,
            },
        ],
        link: '/offices/ground-floor/G5',
        objectPosition: '0 50%',
        display: process.env.REACT_APP_DISPLAY_G5,
    },
];

export default groundFloorOffices;
