import React from 'react';
import styled from 'styled-components';
import {MDBRow, MDBCol} from 'mdb-react-ui-kit';

const Styles = styled.div`
    h2 {
        padding: 0.3rem 3rem;
    }
`;

function TitleContainer({title}) {
    return (
        <Styles>
            <MDBRow className="green-container">
                <MDBCol size="12">
                    <div>
                        <h2>{title}</h2>
                    </div>
                </MDBCol>
            </MDBRow>
        </Styles>
    );
}

export default TitleContainer;
