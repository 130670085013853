import floorplan_L1 from "../images/L1-floorplan-v.png";

import L1_1 from '../images/L1-1.jpg';
import L1_2 from '../images/L1-2.jpg';
import L1_3 from '../images/L1-3.jpg';

const loftOffices = [
    {
        name: 'L1',
        items: [{name: 'Offices', link: '/offices'}, {name: 'Loft', link: '/offices/loft'}],
        title: 'Loft - Office 1',
        price: '£1,150 pcm',
        size: '30m²',
        available: '01/09/2024',
        minTerm: '12 months',
        maxTerm: '36 months',
        description: 'A large loft space with plenty of character!',
        features: ['Bright and inviting workspace with multiple skylights',
            'Versatile floorspace suited to different layouts',
            'Unique style to make a statement'],
        images: [
            {
                original: L1_1,
                thumbnail: L1_1,
            },
            {
                original: L1_2,
                thumbnail: L1_2,
            },
            {
                original: L1_3,
                thumbnail: L1_3,
            },
            {
                original: floorplan_L1,
                thumbnail: floorplan_L1,
            },
        ],
        link: '/offices/loft/L1',
        objectPosition: '0 50%',
        display: process.env.REACT_APP_DISPLAY_L1,
    },
];

export default loftOffices;
