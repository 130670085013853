import React from 'react';
import styled from 'styled-components';
import {
    MDBContainer, MDBRow, MDBCol, MDBIcon, MDBCard, MDBCardHeader, MDBCardBody, MDBCardText,
    MDBCardLink, MDBCardImage,
} from 'mdb-react-ui-kit';
import ContactBar from '../components/ContactBar';
import officeOutside from '../assets/images/office-outside.jpg';
import basementOffices from '../assets/data/basementOffices';
import groundFloorOffices from '../assets/data/groundFloorOffices';
import firstFloorOffices from '../assets/data/firstFloorOffices';
import secondFloorOffices from "../assets/data/secondFloorOffices";
import loftOffices from "../assets/data/loftOffices";

const Styles = styled.div`
    .page-title {
        color: var(--mdb-gray-300);
    }
    
    img.shadow-image {
        max-width: 475px;
        width: 95%;
        height: auto;
        filter: brightness(0.8);
        display: inline-block;
        border: 2px solid var(--mdb-gray-300);
        box-shadow: -10px -10px 0 var(--mdb-gray-700);
    }
       
    @media only screen and (max-width: 768px) {
        #office-overview {
            text-align: center;
        }
        
        img.shadow-image {
            max-width: 475px;
            width: 65%;
        }
    }
    
    .hr {
        color: var(--mdb-green);
        opacity: 1;
    }
    
    i {
        padding-right: 5px;
    }
`;

export default function Home() {
    const allOffices = Array.prototype.concat.apply([], [basementOffices, groundFloorOffices, firstFloorOffices, secondFloorOffices, loftOffices])

    return (
        <Styles>
            <MDBContainer fluid>
                <MDBRow className="d-flex align-items-center">
                    <MDBCol sm="12" md="6" className="text-center">
                        <img src={officeOutside} alt="office-outside" className="m-4 shadow-image"/>
                    </MDBCol>
                    <MDBCol sm="12" md="6">
                        <div id="office-overview">
                            <MDBRow className="py-3 page-title">
                                <h1>A Jewel in the Heart of Cardiff</h1>
                                <p>35 Park Place is a fully refurbished Victorian mansion, accommodating small to medium
                                    sized businesses within a convenient and high value all inclusive package. The
                                    building overlooks Cardiff museum and the Gorsedd Gardens, with City Hall on the
                                    next block. Whilst set in these tranquil yet dynamic surroundings, it is only 5
                                    minutes walk to the city centre.</p>
                                <p>We are also a key part of the capital's future, situated squarely within the Business
                                    Improvement District (BID), a long term programme aimed at improving the trading
                                    environment.</p>
                                <p>They really don't make them like this anymore: high ceilings, thick walls and
                                    Burgess brick elegance. This is the place to do business.</p>
                            </MDBRow>
                            <hr className="hr"/>
                            <MDBRow className="py-3">
                                <h3>Why Choose 35 Park Place?</h3>
                                <p><MDBIcon fas icon="check"/> Fully refurbished and carpeted offices</p>
                                <p><MDBIcon fas icon="check"/> Business-grade broadband with 5G backup</p>
                                <p><MDBIcon fas icon="check"/> Wi-fi throughout, including communal areas, and separate
                                    offices can be interlinked via the managed IT service</p>
                                <p><MDBIcon fas icon="check"/> CCTV covering the essential areas</p>
                                <p><MDBIcon fas icon="check"/> Electric entry system and intercom in every office</p>
                                <p><MDBIcon fas icon="check"/> Managed central heating</p>
                            </MDBRow>
                        </div>
                    </MDBCol>
                </MDBRow>
                <MDBRow className="green-container">
                    <MDBCol size="12">
                        <div className="m-5">
                            <h2>Available Offices</h2>
                            <MDBRow>
                                <p>Please use the menu above to check out all of our available offices.</p>
                            </MDBRow>
                            {allOffices.filter(o => o.display === 'true').length > 0 ? (
                                <MDBRow className="row-cols-1 row-cols-sm-2 row-cols-md-3">
                                    {allOffices.filter(o => o.display === 'true').slice(0, 3).map((service, index) => {
                                        return (
                                            <MDBCol key={index} className="my-2">
                                                <MDBCard className="h-100">
                                                    <MDBCardImage src={service.images[0].original} alt={service.name} position="top"
                                                                  style={{height: 200}}/>
                                                    <MDBCardHeader>{service.name}</MDBCardHeader>
                                                    <MDBCardBody>
                                                        <MDBCardText>
                                                            {service.description}
                                                        </MDBCardText>
                                                        <MDBCardLink href={service.link}>Find out more ></MDBCardLink>
                                                    </MDBCardBody>
                                                </MDBCard>
                                            </MDBCol>
                                        )
                                    })}
                                </MDBRow>
                            ) : (
                                <div className="unavailable">
                                    <MDBRow className="my-3">
                                        <p>There are currently no available offices.</p>
                                    </MDBRow>
                                </div>
                            )}
                        </div>
                    </MDBCol>
                </MDBRow>
                <ContactBar/>
            </MDBContainer>
        </Styles>
    )
}
