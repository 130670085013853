import React from 'react';
import styled from 'styled-components';
import {MDBFooter, MDBContainer, MDBRow, MDBCol} from 'mdb-react-ui-kit';

const Styles = styled.div`
    footer {
        background-color: var(--mdb-black);
        border-top: 1px solid var(--mdb-green);
            
        .footer-small {
            font-size: 0.75em;
        }
    
        .row {
            p {
                color: var(--mdb-gray-300);
            }
    
            div {
                &:not(:first-child) {
                    @media only screen and (min-width: 768px) {
                        border-left: 1px solid var(--mdb-green);
                    }
                }
    
                p {
                    margin-bottom: 0;
                }
    
                a, i {
                    color: var(--mdb-gray-300);
    
                    &:hover {
                        color: var(--mdb-white);
                        text-decoration: none;
                    }
                }
            }
        }
    }
`;

export const Footer = () => {
    return (
        <Styles>
            <MDBFooter>
                <MDBContainer className="p-3">
                    <MDBRow className="text-center footer-small">
                        <p>Thirty Five Park Place Ltd. is registered in England, Suite D, The Business Centre, Faringdon
                            Avenue, Romford, Essex RM3 8EN</p>
                        <p><b>Company No.</b> 12743836</p>
                    </MDBRow>
                    <MDBRow className="footer-small pt-3">
                        <MDBCol lg="3" md="3" className="mb-md-0 text-center">
                            <p>&copy; {new Date().getFullYear()} Thirty Five Park Place Ltd.</p>
                        </MDBCol>
                        <MDBCol lg="3" md="3" className="mb-md-0 text-center">
                            <a href="/accessibility">Accessibility</a>
                        </MDBCol>
                        <MDBCol lg="3" md="3" className="mb-md-0 text-center">
                            <a href="/privacypolicy">Privacy Policy</a>
                        </MDBCol>
                        <MDBCol lg="3" md="3" className="mb-md-0 text-center">
                            <a href="/terms">Terms</a>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </MDBFooter>
        </Styles>
    );
}
