import React from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import {
    MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardHeader, MDBCardBody, MDBCardText, MDBCardLink,
    MDBCardImage,
} from 'mdb-react-ui-kit';
import TitleContainer from "../components/TitleContainer";
import Breadcrumb from '../components/Breadcrumb';
import basementOffices from '../assets/data/basementOffices';
import groundFloorOffices from '../assets/data/groundFloorOffices';
import firstFloorOffices from '../assets/data/firstFloorOffices';
import secondFloorOffices from "../assets/data/secondFloorOffices";
import loftOffices from "../assets/data/loftOffices";

const Styles = styled.div`
    .offices-slick-title {
        padding: 0 3.5rem 0 3.5rem;

        a {
            color: var(--mdb-green);

            &:hover {
                text-decoration: none;
            }
        }
    }
    
    .slick-next:before, .slick-prev:before {
        color: var(--mdb-green)
    }
`;

export default function Offices() {
    const displayBasement = process.env.REACT_APP_DISPLAY_BASEMENT;
    const displayGroundFloor = process.env.REACT_APP_DISPLAY_GROUND_FLOOR;
    const displayFirstFloor = process.env.REACT_APP_DISPLAY_FIRST_FLOOR;
    const displaySecondFloor = process.env.REACT_APP_DISPLAY_SECOND_FLOOR;
    const displayLoft = process.env.REACT_APP_DISPLAY_LOFT;

    const title = 'Offices';
    const responsiveSettings = [
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
            },
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            },
        },
        {
            breakpoint: 576,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ];
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
            ...responsiveSettings,
        ],
    };

    return (
        <Styles>
            <MDBContainer fluid className="px-0 overflow-hidden">
                <TitleContainer title={title}/>
                <Breadcrumb active={title}/>
                <MDBRow>
                    <MDBCol size="12">
                        <div className="mx-5 mb-4">
                            <h5>Select an office below to find out more:</h5>
                        </div>
                    </MDBCol>
                </MDBRow>
                {(displayBasement === 'true' && basementOffices.filter(o => o.display === 'true').length > 0) &&
                    <MDBRow>
                        <MDBCol size="12">
                            <MDBContainer fluid>
                                <div className="offices-slick-title">
                                    <MDBRow className="align-items-center">
                                        <MDBCol size="8">
                                            <h3>Basement</h3>
                                        </MDBCol>
                                        <MDBCol size="4" className="d-flex justify-content-end">
                                            <a href="/offices/basement">Find out more ></a>
                                        </MDBCol>
                                    </MDBRow>
                                </div>
                                <div className="ms-5 me-5 mb-5">
                                    <Slider {...settings} infinite={basementOffices.length >= 3}
                                            className="row-cols-12">
                                        {basementOffices.filter(o => o.display === 'true').map((service, index) => {
                                            return (
                                                <MDBCol key={index} className="px-2 py-2">
                                                    <MDBCard className="h-100">
                                                        <MDBCardImage src={service.images[0].original} alt={service.name}
                                                                      position="top"
                                                                      style={{
                                                                          height: 150,
                                                                          objectPosition: service.objectPosition,
                                                                      }}/>
                                                        <MDBCardHeader>{service.name}</MDBCardHeader>
                                                        <MDBCardBody>
                                                            <MDBCardText>
                                                                {service.description}
                                                            </MDBCardText>
                                                            <MDBCardLink href={service.link}>Find out more
                                                                ></MDBCardLink>
                                                        </MDBCardBody>
                                                    </MDBCard>
                                                </MDBCol>
                                            )
                                        })}
                                    </Slider>
                                </div>
                            </MDBContainer>
                        </MDBCol>
                    </MDBRow>
                }
                {(displayGroundFloor === 'true' && groundFloorOffices.filter(o => o.display === 'true').length > 0) &&
                    <MDBRow>
                        <MDBCol size="12">
                            <MDBContainer fluid>
                                <div className="offices-slick-title">
                                    <MDBRow className="align-items-center">
                                        <MDBCol size="8">
                                            <h3>Ground Floor</h3>
                                        </MDBCol>
                                        <MDBCol size="4" className="d-flex justify-content-end">
                                            <a href="/offices/ground-floor">Find out more ></a>
                                        </MDBCol>
                                    </MDBRow>
                                </div>
                                <div className="ms-5 me-5 mb-5">
                                    <Slider {...settings} infinite={groundFloorOffices.length >= 3}
                                            className="row-cols-12">
                                        {groundFloorOffices.filter(o => o.display === 'true').map((service, index) => {
                                            return (
                                                <MDBCol key={index} className="px-2 py-2">
                                                    <MDBCard className="h-100">
                                                        <MDBCardImage src={service.images[0].original} alt={service.name}
                                                                      position="top"
                                                                      style={{
                                                                          height: 150,
                                                                          objectPosition: service.objectPosition,
                                                                      }}/>
                                                        <MDBCardHeader>{service.name}</MDBCardHeader>
                                                        <MDBCardBody>
                                                            <MDBCardText>
                                                                {service.description}
                                                            </MDBCardText>
                                                            <MDBCardLink href={service.link}>Find out more
                                                                ></MDBCardLink>
                                                        </MDBCardBody>
                                                    </MDBCard>
                                                </MDBCol>
                                            )
                                        })}
                                    </Slider>
                                </div>
                            </MDBContainer>
                        </MDBCol>
                    </MDBRow>
                }
                {(displayFirstFloor === 'true' && firstFloorOffices.filter(o => o.display === 'true').length > 0) &&
                    <MDBRow>
                        <MDBCol size="12">
                            <MDBContainer fluid>
                                <div className="offices-slick-title">
                                    <MDBRow className="align-items-center">
                                        <MDBCol size="8">
                                            <h3>First Floor</h3>
                                        </MDBCol>
                                        <MDBCol size="4" className="d-flex justify-content-end">
                                            <a href="/offices/first-floor">Find out more ></a>
                                        </MDBCol>
                                    </MDBRow>
                                </div>
                                <div className="ms-5 me-5 mb-5">
                                    <Slider {...settings} infinite={firstFloorOffices.length >= 3}
                                            className="row-cols-12">
                                        {firstFloorOffices.filter(o => o.display === 'true').map((service, index) => {
                                            return (
                                                <MDBCol key={index} className="px-2 py-2">
                                                    <MDBCard className="h-100">
                                                        <MDBCardImage src={service.images[0].original} alt={service.name}
                                                                      position="top"
                                                                      style={{
                                                                          height: 150,
                                                                          objectPosition: service.objectPosition,
                                                                      }}/>
                                                        <MDBCardHeader>{service.name}</MDBCardHeader>
                                                        <MDBCardBody>
                                                            <MDBCardText>
                                                                {service.description}
                                                            </MDBCardText>
                                                            <MDBCardLink href={service.link}>Find out more
                                                                ></MDBCardLink>
                                                        </MDBCardBody>
                                                    </MDBCard>
                                                </MDBCol>
                                            )
                                        })}
                                    </Slider>
                                </div>
                            </MDBContainer>
                        </MDBCol>
                    </MDBRow>
                }
                {(displaySecondFloor === 'true' && secondFloorOffices.filter(o => o.display === 'true').length > 0) &&
                    <MDBRow>
                        <MDBCol size="12">
                            <MDBContainer fluid>
                                <div className="offices-slick-title">
                                    <MDBRow className="align-items-center">
                                        <MDBCol size="8">
                                            <h3>Second Floor</h3>
                                        </MDBCol>
                                        <MDBCol size="4" className="d-flex justify-content-end">
                                            <a href="/offices/second-floor">Find out more ></a>
                                        </MDBCol>
                                    </MDBRow>
                                </div>
                                <div className="ms-5 me-5 mb-5">
                                    <Slider {...settings} infinite={secondFloorOffices.length >= 3}
                                            className="row-cols-12">
                                        {secondFloorOffices.filter(o => o.display === 'true').map((service, index) => {
                                            return (
                                                <MDBCol key={index} className="px-2 py-2">
                                                    <MDBCard className="h-100">
                                                        <MDBCardImage src={service.images[0].original} alt={service.name}
                                                                      position="top"
                                                                      style={{
                                                                          height: 150,
                                                                          objectPosition: service.objectPosition,
                                                                      }}/>
                                                        <MDBCardHeader>{service.name}</MDBCardHeader>
                                                        <MDBCardBody>
                                                            <MDBCardText>
                                                                {service.description}
                                                            </MDBCardText>
                                                            <MDBCardLink href={service.link}>Find out more
                                                                ></MDBCardLink>
                                                        </MDBCardBody>
                                                    </MDBCard>
                                                </MDBCol>
                                            )
                                        })}
                                    </Slider>
                                </div>
                            </MDBContainer>
                        </MDBCol>
                    </MDBRow>
                }
                {(displayLoft === 'true' && loftOffices.filter(o => o.display === 'true').length > 0) &&
                    <MDBRow>
                        <MDBCol size="12">
                            <MDBContainer fluid>
                                <div className="offices-slick-title">
                                    <MDBRow className="align-items-center">
                                        <MDBCol size="8">
                                            <h3>Loft</h3>
                                        </MDBCol>
                                        <MDBCol size="4" className="d-flex justify-content-end">
                                            <a href="/offices/loft">Find out more ></a>
                                        </MDBCol>
                                    </MDBRow>
                                </div>
                                <div className="ms-5 me-5 mb-5">
                                    <Slider {...settings} infinite={loftOffices.length >= 3}
                                            className="row-cols-12">
                                        {loftOffices.filter(o => o.display === 'true').map((service, index) => {
                                            return (
                                                <MDBCol key={index} className="px-2 py-2">
                                                    <MDBCard className="h-100">
                                                        <MDBCardImage src={service.images[0].original} alt={service.name}
                                                                      position="top"
                                                                      style={{
                                                                          height: 150,
                                                                          objectPosition: service.objectPosition,
                                                                      }}/>
                                                        <MDBCardHeader>{service.name}</MDBCardHeader>
                                                        <MDBCardBody>
                                                            <MDBCardText>
                                                                {service.description}
                                                            </MDBCardText>
                                                            <MDBCardLink href={service.link}>Find out more
                                                                ></MDBCardLink>
                                                        </MDBCardBody>
                                                    </MDBCard>
                                                </MDBCol>
                                            )
                                        })}
                                    </Slider>
                                </div>
                            </MDBContainer>
                        </MDBCol>
                    </MDBRow>
                }
            </MDBContainer>
        </Styles>
    )
}
